import { useCallback, JSX } from "react";

import { Button } from "@mobsuccess-devops/react-ui/_PandaArk";

import { useAuth } from "../../../../public/auth/auth";

type PatSignInEnvProps = {
  profile: string;
  pat: string;
};

export function PatSignInEnv({ profile, pat }: PatSignInEnvProps): JSX.Element {
  const auth = useAuth();
  const handleLogIn = useCallback(() => {
    auth.signInWithPat({ pat, profile });
  }, [auth, pat, profile]);
  return (
    <Button variant="light" onClick={handleLogIn}>
      {profile}
    </Button>
  );
}
