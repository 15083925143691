import { JSX } from "react";

import { Trans } from "@lingui/macro";
import { Typo } from "@mobsuccess-devops/react-ui/Typo";
import { css } from "@mobsuccess-devops/styled-system/css";
import { VStack } from "@mobsuccess-devops/styled-system/jsx";

import { PatSignInEnv } from "./PatSignInEnv";

export function PatSignIn(): JSX.Element {
  return (
    <VStack gap="md">
      <Typo.Body
        className={css({
          color: "interface.400",
        })}
      >
        <Trans>#Auth.Login.PatSignIn.header</Trans>
      </Typo.Body>
      <VStack gap="sm">
        {Object.keys(import.meta.env.MS_PATS).map((profile) => (
          <PatSignInEnv
            key={profile}
            profile={profile}
            pat={import.meta.env.MS_PATS[profile]}
          />
        ))}
      </VStack>
    </VStack>
  );
}
