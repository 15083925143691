import { StrictMode, JSX } from "react";

import { i18n } from "@lingui/core";
import { NotificationWrapper } from "@mobsuccess-devops/react-ui/Notification";
import { ThemeProvider } from "@mobsuccess-devops/react-ui/Theme";
import { Toaster } from "@mobsuccess-devops/react-ui/_PandaArk";
import AppRouter from "@mobsuccess-devops/vite-plugins/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { createRoot } from "react-dom/client";

import { AuthProvider } from "../components/Auth";
import DataContextProvider from "../components/DataContextProvider";
import FlashMessages from "../components/FlashMessages";
import { configure } from "../public/auth/auth";
import { Localized, type LocaleModule } from "../public/i18n/Localized";
import { activate, buildLocales } from "../public/i18n/i18n";
import { BusinessUnits } from "../types/enums";

import { getClient } from "./business-unit";
import { type BranchConfig } from "./data";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

type CreateRootOptions = {
  businessUnit: BusinessUnits;
  entrypoint: string;
  lingui: {
    defaultLanguage: string;
    locales: Record<string, LocaleModule>;
  };
  auth?: {
    userPoolsId: string;
    googleSignupUrl: URL;
    forgotPasswordUrl: URL;
    googleClientId: string;
    userPoolsWebClientId: string;
  };
  data?: {
    host: string;
    environment?: "production" | "development";
    services: Record<string, string>;
    branchesOverrides?: Record<string, BranchConfig>;
  };
};

export async function createAppRoot({
  auth,
  data,
  lingui,
  entrypoint,
  businessUnit,
}: CreateRootOptions): Promise<void> {
  const root = window?.top?.document.getElementById(
    `react_root--${entrypoint}`,
  );
  if (!root) {
    throw new Error(`Could not find root element for ${entrypoint}`);
  }
  if (auth) {
    configure(auth);
  }
  const locales = buildLocales(lingui.locales);

  const client = getClient();

  await activate(i18n, locales, lingui.defaultLanguage);

  const AuthWrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
    if (auth) {
      return (
        <AuthProvider
          businessUnit={businessUnit}
          googleClientId={auth.googleClientId}
          googleSignupUrl={auth.googleSignupUrl}
          forgotPasswordUrl={auth.forgotPasswordUrl}
          userPoolsWebClientId={auth.userPoolsWebClientId}
        >
          {children}
        </AuthProvider>
      );
    }
    return <>{children}</>;
  };

  const DataWrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
    if (data) {
      return (
        <DataContextProvider
          host={data.host}
          services={data.services}
          environment={data.environment}
          branchOverrides={data.branchesOverrides}
        >
          {children}
        </DataContextProvider>
      );
    }
    return <>{children}</>;
  };

  return createRoot(root).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <ThemeProvider client={client}>
          <NotificationWrapper>
            <Toaster>
              <Localized i18n={i18n} locales={locales}>
                <FlashMessages>
                  <AuthWrapper>
                    <DataWrapper>
                      <AppRouter />
                    </DataWrapper>
                  </AuthWrapper>
                </FlashMessages>
              </Localized>
            </Toaster>
          </NotificationWrapper>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>,
  );
}
